import { InsuranceDataTypes } from 'pages/IntroCreditLifeInsurance/types';
import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';

class InsuranceInfoService extends HttpClient {
  public constructor() {
    super(Services.InsuranceInfo);
  }

  public getInsuranceInfo = () => {
    return this.instance.get<InsuranceDataTypes>('/infos');
  };
}

export default new InsuranceInfoService();
