import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { storageKeys } from 'store/storage-keys';
import {
  SimulationConfigType,
  SimulationValueType,
} from 'types/Loans/SimulationDataType';
import { UtmDetailsType } from 'types/Tracker/TrackingType';
import { storage } from 'utils/storage';

interface GetSimulationSettingsResponse {
  data: {
    settings: {
      grace_period: number;
      max_period: number;
      max_value: number;
      min_period: number;
      min_value: number;
    };
  };
}

type CreateSimulationProps = {
  utm: UtmDetailsType;
  offer_id?: string;
};

type InsuranceIntentionProps = {
  insurance_intention: boolean;
};

export type GenderTypes = 'M' | 'F' | 'O' | null;

class SimulationService extends HttpClient {
  public constructor() {
    super(Services.PlatformLoans);
  }

  public createSimulation = (payload: CreateSimulationProps) => {
    let requestBody = {};
    requestBody = {
      fingerprint: {
        device_info: 'web',
      },
    };

    if (payload?.utm) {
      requestBody = {
        fingerprint: {
          device_info: 'web',
        },
        utm: payload?.utm,
      };
    }

    if (payload?.offer_id) {
      requestBody = {
        ...requestBody,
        offer_id: payload?.offer_id,
      };
    }

    return this.instance.post('', requestBody);
  };

  public getSimulationSettings = () => {
    return this.instance.get<GetSimulationSettingsResponse>(
      '/simulation-settings',
    );
  };

  public simulationValue = (body: SimulationValueType) => {
    const requestBody = {
      requested_amount: body.requestedAmount,
      requested_period: body.requestedPeriod,
      requested_payday: body.requestedPayday,
    };

    return this.instance.patch(
      `${storage.getItem(storageKeys.loanUid)}`,
      requestBody,
    );
  };

  public simulationConfig = (body: SimulationConfigType) => {
    return this.instance.patch(`${storage.getItem(storageKeys.loanUid)}`, body);
  };

  public setRequestAmount = (requested_amount: number) => {
    const requestBody = {
      requested_amount,
    };

    return this.instance.patch(
      `${storage.getItem(storageKeys.loanUid)}`,
      requestBody,
    );
  };

  public setInsuranceIntention = ({
    insurance_intention,
  }: InsuranceIntentionProps) => {
    const requestBody = {
      insurance_intention,
    };

    return this.instance.patch(
      `${storage.getItem(storageKeys.loanUid)}`,
      requestBody,
    );
  };

  public setGender = (value: GenderTypes) => {
    const requestBody = {
      gender: value,
    };

    return this.instance.patch(
      `${storage.getItem(storageKeys.loanUid)}`,
      requestBody,
    );
  };

  public setSocialName = (value: string) => {
    const requestBody = {
      social_name: value,
    };

    return this.instance.patch(
      `${storage.getItem(storageKeys.loanUid)}`,
      requestBody,
    );
  };
}

export default new SimulationService();
