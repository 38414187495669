import Skeleton from 'react-loading-skeleton';

import { Box } from 'components/Box';

type LoadingProps = {
  isCard?: boolean;
};

export const Loading = ({ isCard }: LoadingProps) => {
  return (
    <>
      {!isCard &&
        Array.from([1, 3, 5]).map((element) => (
          <Box
            key={element}
            _style={{
              flex: {
                flexDirection: 'row',
                gap: '1rem',
              },
            }}
          >
            <Skeleton
              style={{
                height: '50px',
                width: '50px',
                borderRadius: '50%',
              }}
            />

            <div
              style={{
                width: '100%',
              }}
            >
              <Skeleton
                style={{
                  height: '50px',
                }}
              />
            </div>
          </Box>
        ))}

      {isCard && (
        <Box
          _style={{
            flex: {
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'center',
            },
            margin: '1rem',
          }}
        >
          <Skeleton
            style={{
              height: '50px',
              width: '50px',
              borderRadius: '50%',
            }}
          />

          <div
            style={{
              width: '50%',
            }}
          >
            <Skeleton
              style={{
                height: '50px',
              }}
            />
          </div>

          <div
            style={{
              width: '50%',
            }}
          >
            <Skeleton
              style={{
                height: '50px',
              }}
            />
          </div>
        </Box>
      )}
    </>
  );
};
