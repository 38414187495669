const baseUrl = process.env.REACT_APP_API_URL;

export const Services = {
  Authorizer: `${baseUrl}/v1/auth`,
  Agreements: `${baseUrl}/v1/agreements`,
  PlatformLoans: `${baseUrl}/v1/loans`,
  Address: `${baseUrl}/v1/address`,
  Banks: `${baseUrl}/v1/banks`,
  Loans: {
    remote: {
      v0: `${baseUrl}/v0`,
      v1: `${baseUrl}/v1`,
      v2: `${baseUrl}/v2`,
    },
  },
  Tracker: `${baseUrl}/v1/tracker`,
  Zendesk: `https://noverde.zendesk.com/api/v2/help_center`,
  LandingPage: `${baseUrl}/v1/portal`,
  Payments: `${baseUrl}/v1/payments`,
  PlatformIR: `${baseUrl}/v1/ir`,
  Pix: `${baseUrl}/v1/pix`,
  Certiface: `${baseUrl}/v1/certiface`,
  InfoContract: `${baseUrl}/v1/rcvbl/loans`,
  Paymt: `${baseUrl}/v1/paymt`,
  CollectionOffers: `${baseUrl}/v1/colct`,
  Supersim: `${baseUrl}/v1/supersim/proposals`,
  InsuranceInfo: `${baseUrl}/v1/insurance`,
};
