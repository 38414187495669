import Slider from 'react-slick';

import { Box } from 'components/Box';
import { Typography } from 'components/Typography';
import { getThemeColors } from 'styles/helpers/getThemeColors';

import { useInsuranceInfo } from '../hook/useInsuranceInfo';
import { Loading } from './Loading';

import { Divider } from './styles';

const colors = getThemeColors();

type InsuranceCoverageProps = {
  showRequirements?: boolean;
};
export const InsuranceCoverage = ({
  showRequirements,
}: InsuranceCoverageProps) => {
  const { coverage, isLoading } = useInsuranceInfo();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      {isLoading && (
        <Box
          _style={{
            borderRadius: '8px',
            border: `1px solid ${colors['neutral-medium-2']}`,
            size: {
              minHeight: '160px',
            },
          }}
        >
          <Loading isCard />
        </Box>
      )}

      {coverage.map((item) => (
        <Box
          key={String(item.title)}
          _style={{
            borderRadius: '8px',
            border: `1px solid ${colors['neutral-medium-2']}`,
            size: {
              minHeight: '160px',
            },
          }}
        >
          {isLoading && <Loading isCard />}

          <Box
            key={String(item.title)}
            _style={{
              flex: {
                flexDirection: 'column',
                gap: '1rem',
                alignItems: 'center',
              },
              padding: '1rem',
            }}
          >
            <img
              style={{ width: '24px', height: '24px' }}
              src={item.logo_url}
              alt="briefcase"
            />

            <Typography
              _style={{
                default: 'body_b_mobile',
                xs: 'body_b_desktop',
                textAlign: 'center',
              }}
              as="p"
            >
              {item.title}
            </Typography>

            <Typography
              _style={{
                default: 'body',
                xs: 'body-desktop',
                textAlign: 'center',
              }}
              as="p"
            >
              {item.description}
            </Typography>

            {item?.requirements && showRequirements && (
              <>
                <Divider />

                <Typography
                  _style={{
                    default: 'body',
                    xs: 'body-desktop',
                    textAlign: 'center',
                  }}
                  as="p"
                >
                  Documento necessário: <strong>{item.requirements}</strong>
                </Typography>
              </>
            )}
          </Box>
        </Box>
      ))}
    </Slider>
  );
};
