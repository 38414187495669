import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

export const LabelStyled = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const SelectContainer = styled.div`
  > div {
    &.error {
      > div {
        &.installments__control {
          border: 1px solid ${colors.errors};
        }
        &.installments__control--is-focused {
          box-shadow: none;
        }
      }
    }
    > div {
      &.installments__control {
        width: 100%;
        height: 56px;
        padding: 0 15px;
        border: 1px solid ${colors.text};
        border-radius: 8px;
        z-index: 10;

        background: ${colors.white};
        color: ${colors.text};

        outline: none;
      }
      &.installments__control--is-focused {
        border: 1px solid ${colors.noverde};
        box-shadow: 0 0 0 1px ${colors.noverde};
      }
      > div {
        &.installments__menu-list {
          margin: 5px;
          ::-webkit-scrollbar {
            width: 6px;
          }
          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px transparent;
          }
          ::-webkit-scrollbar-thumb {
            background: #e0e0e0;
            border-radius: 20px;
          }
          ::-webkit-scrollbar-thumb:hover {
            background: #aaa;
          }
        }
        &.installments__value-container {
          padding: 0;
        }
        > span {
          &.installments__indicator-separator {
            display: none;
          }
        }
        > div {
          &.installments__indicator {
            color: transparent;

            width: 0;
            height: 0;
            padding: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;

            border-top: 5px solid ${colors.text};
          }
          &.installments__option--is-focused {
            background-color: transparent;
          }
          &.installments__option--is-selected {
            background-color: transparent;
          }
        }
      }
    }
  }
`;
