import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';
import { dotzTheme } from 'styles/themes/dotz';

const colors = getThemeColors({
  dotz: {
    white: dotzTheme.colorNeutralDarkPure,
  },
});

export const OfferApprovedContainer = styled(motion.div)`
  width: 100%;
  text-align: center;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeadContent = styled.header`
  margin-bottom: 5vh;

  > svg {
    margin-bottom: 20px;
  }
`;

export const ContentHolder = styled.main`
  font-size: 14px;
  padding: 15px 0;
  border-radius: 5px;
  margin-bottom: 5vh;
  color: ${colors.white};

  > a {
    line-height: 40px;
  }
`;

export const FooterHolder = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface InstallmentsDataHolderProps {
  isBordered?: boolean;
  isBest?: boolean;
  noBorder?: boolean;
}

export const InstallmentsDataHolder = styled.div<InstallmentsDataHolderProps>`
  padding: 16px 8px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 100px;
  align-items: center;
  grid-column-gap: 16px;

  ${({ isBordered, isBest }) => css`
    background-color: ${isBordered && !isBest
      ? transparentize(0.9, colors.greyborders)
      : isBest
      ? transparentize(0.95, colors.blue1)
      : 'white'};
    border: ${isBest
      ? `1px solid ${colors.blue1}`
      : `1px solid ${colors.greyborders}`};
    border-radius: ${isBordered ? '6px' : 0};
    border-left: ${!isBordered && 0};
    border-right: ${!isBordered && 0};
    border-bottom: ${!isBordered && 0};
  `}

  border: ${({ noBorder }) => noBorder && 'none'};

  > div {
    text-align: left;
  }
`;

export const ApprovedValue = styled.h1`
  color: ${colors.white};
  font-size: 36px;
  font-weight: 600;

  @media ${device.sm} {
    font-size: 48px;
  }
`;

interface BoldProps {
  fontSize?: string;
  fontWeight?: string;
}

export const Bold = styled.p<BoldProps>`
  ${({ fontSize, fontWeight }) => css`
    color: ${colors.galaxy};
    font-size: ${fontSize || '18px'};
    font-weight: ${fontWeight || 700};
  `}
`;

export const ModalContent = styled.div`
  margin: 0 15px;
  white-space: pre-wrap;
  > button {
    margin: 0 auto;

    &.secondary {
      margin-top: 16px;
    }
  }
`;

export const LinkNoverde = styled.a`
  color: ${colors.noverde};
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  text-align: right;
`;

export const InstallmentLabel = styled.p`
  font-size: 14px;
`;

export const Money = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 16px 16px 0px;
  height: 80vh;
  width: 100%;
  @media ${device.sm} {
    width: 532px;
  }
  @media ${device.lg} {
    padding: 16px 0px 16px;
  }
`;

export const TopBanner = styled.div`
  width: 100%;
  background-color: ${colors.noverde};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;

  p {
    color: ${colors.white};
    font-size: 16px;
  }
`;

export const Stack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 99vh;

  @media ${device.lg} {
    height: 100vh;
  }
`;

export const Content = styled.div`
  max-width: 200px;
`;

export const ApprovedAnimationHolder = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  z-index: 9999;

  background-color: ${colors.noverde};
`;

export const ApprovedLabel = styled.div`
  color: ${colors.white};
  font-size: 20px;
  font-weight: 600;

  margin-top: 24px;

  @media ${device.sm} {
    font-size: 40px;
  }
`;
export const ContainerSlider = styled.div`
  min-width: 100%;
  max-width: 532px;
`;

export const ContainerValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 300px;
`;
