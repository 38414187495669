import Checkbox from 'components/Checkbox';
import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { dotzTheme } from 'styles/themes/dotz';

const colors = getThemeColors({
  dotz: {
    white: dotzTheme.colorNeutralDarkPure,
  },
});

export const CustomCheckbox = styled(Checkbox)`
  &:checked {
    border-color: ${colors.galaxy};
    background: ${colors.galaxy};
    &:before {
      opacity: 1;
    }
  }
`;
