/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';
import { dotzTheme } from 'styles/themes/dotz';

const colors = getThemeColors({
  dotz: {
    'brand-primary': dotzTheme.colorBrandPrimaryPure,
    'brand-tertiary': dotzTheme.colorBrandPrimaryLight,
  },
});

export const ContainerIcon = styled.div`
  background: ${colors['brand-tertiary']};
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.xs} {
    width: 56px;
    height: 56px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  background: ${colors.greyborders};
`;
