import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/Button';
import { MessageCallout } from 'components/MessageCallout';
import { PasswordField } from 'components/PasswordField';
import { Presentation } from 'components/Presentation';
import { CreateAccountContext } from 'contexts/CreateAccountContext';
import { useDBTracking } from 'contexts/DBTrackingContext';
import { useModal } from 'hooks/useModal';
import { useTracking } from 'hooks/useTracking';
import TermsOfUseService from 'services/Agreements/TermsOfUseService';
import PasswordFormService from 'services/Authorizer/PasswordFormService';
import LoansService from 'services/PlatformLoans/LoansService';
import { storageKeys } from 'store/storage-keys';
import { CreatePasswordVariants } from 'utils/constants/enums';
import { storage } from 'utils/storage';
import { formValidations } from 'utils/validators/form-validations';

import { ModalAcceptance } from '../ModalAcceptance';

import { PasswordFormContainer, FormHolder, TermsText } from './style';

interface PasswordFormProps {
  reset?: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

type AgreementContentProps = {
  agreementsData?: {
    name: string;
    content: {
      __html: string;
    };
  }[];
};

export function PasswordForm(props: PasswordFormProps) {
  const { reset, setIsLoading, setIsDirty } = props;
  const { RESET, MARKETPLACE, CREATE_PASSWORD } = CreatePasswordVariants;
  const methods = useForm({ mode: 'all' });
  const { handleSubmit, getValues, clearErrors, setError, formState } = methods;
  const { openModal, closeModal } = useModal();
  const { createPassword, confirmPassword } = formValidations();
  const history = useHistory();
  const [message, setMessage] = useState(<></>);
  const [agreementsIds, setAgreementsIds] = useState<any[]>([]);
  const [isLoadingContent, setIsLoadingContent] = useState(false);

  const [agreementContent, setAgreementContent] =
    useState<AgreementContentProps>();
  const { authToken } = useContext(CreateAccountContext);
  const { trackEvent, trackProperty } = useTracking();
  const { setTrackingEvent, utmData } = useDBTracking();

  function onSubmit(data) {
    switch (reset) {
      case RESET:
        handleReset(data);
        break;
      case MARKETPLACE:
        handleCreatePassword(data);
        break;
      case CREATE_PASSWORD:
        handleCreatePassword(data);
        break;
      default:
        break;
    }
  }

  async function handleReset(data) {
    try {
      setIsLoading(true);

      storage.setItem(storageKeys.accessToken, authToken);

      await PasswordFormService.createPassword({
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
      });

      trackEvent('select_item', {
        item_name: 'alterar_senha',
      });

      storage.removeItem(storageKeys.accessToken);

      history.push('/login', {
        origin: history?.location?.pathname,
      });
    } catch (error) {
      setMessage(
        <MessageCallout
          type="error"
          title="Falha ao finalizar seu cadastro"
          message="Não foi possível finalizar seu cadastro. Por favor tente novamente mais tarde."
        />,
      );
      console.warn({
        title: 'Erro ao resetar senha',
        error,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCreatePassword(data) {
    localStorage.setItem(storageKeys.accessToken, authToken);

    trackEvent('select_item', {
      item_name: 'li_e_aceito_termos',
      page_title: 'Termos de uso',
    });

    trackEvent('select_item', {
      item_name: 'criar_conta',
    });

    try {
      setIsLoading(true);
      await TermsOfUseService.acceptTerms({
        agreementsIds,
      });
    } catch {
      setMessage(
        <MessageCallout
          type="error"
          title="Falha ao aceitar os termos"
          message="Não foi possível aceitar os termos. Por favor tente novamente mais tarde."
        />,
      );
      setTimeout(() => {
        setMessage(<></>);
      }, 5000);
      return;
    } finally {
      setIsLoading(false);
    }

    try {
      setIsLoading(true);
      await PasswordFormService.createPassword({
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
      });

      trackEvent('sign_up', {
        method: 'documento',
        page_title: 'Termos de uso',
        device_id: storage.getItem(storageKeys.userUid),
        borrower_id: storage.getItem(storageKeys.borrowerId),
      });

      trackProperty({
        borrower_id: storage.getItem(storageKeys.borrowerId),
      });

      const selectedOfferUid = storage.getItem(
        storageKeys.partnerOfferUid,
      ) as string;

      const insuranceIntention = storage.getItem(
        storageKeys.insuranceIntention,
      );

      if (selectedOfferUid) {
        await LoansService.setOfferSelected({
          uuid: selectedOfferUid,
          ...(!!insuranceIntention && {
            insured: insuranceIntention === 'true',
          }),
        });
      }

      history.push('/dashboard');

      if (utmData.utm_source) {
        setTrackingEvent({
          category: 'attribution',
          action: 'login',
          deviceId: true,
          utmData: true,
        });
      }
    } catch {
      setMessage(
        <MessageCallout
          type="error"
          title="Falha ao criar nova senha"
          message="Não foi possível criar sua nova senha. Por favor tente novamente mais tarde."
        />,
      );
      setTimeout(() => {
        setMessage(<></>);
      }, 5000);
    } finally {
      setIsLoading(false);
    }
  }

  function handleModalTerms() {
    trackEvent('page_view', {
      page_title: 'Termos de uso',
    });
    openModal({
      size: 532,
      title: 'Termos e Política Noverde',
      closeButtonPosition: 'right',
      content: (
        <ModalAcceptance
          agreementContent={agreementContent}
          isLoading={isLoadingContent}
        />
      ),
      alignSelf: 'center',
      fixedContent: <Button onClick={() => closeModal()}>Ok,entendi</Button>,
    });
  }

  function setConfirmPasswordError() {
    if (getValues('passwordConfirmation') !== '') {
      if (getValues('passwordConfirmation') !== getValues('password')) {
        setError('passwordConfirmation', {
          type: 'manual',
          message: 'As senhas têm que ser iguais nos dois campos.',
        });
      } else {
        clearErrors();
      }
    }
  }

  const getRetriveTermsOfUse = async () => {
    try {
      setIsLoadingContent(true);
      const { data } = await TermsOfUseService.retriveTermsOfUse();

      // garantindo que venha os dois termos
      if (!data[0] || !data[1]) {
        setMessage(
          <MessageCallout
            type="error"
            title="Falha ao recuperar os documentos"
            message="Não foi possível recuperar os Termos de Uso e Política de Privacidade. Por favor tente novamente mais tarde."
          />,
        );
        // timer para remover o callout de erro
        setTimeout(() => {
          setMessage(<></>);
        }, 5000);
        return;
      }

      // caso tenha os dois termos
      setAgreementsIds([data[0].id, data[1].id]);
      if (data[0].kind === 6) {
        setAgreementContent({
          agreementsData: [
            {
              name: data[0].name,
              content: {
                __html: `${data[0].text}`,
              },
            },
            {
              name: data[1].name,
              content: {
                __html: `${data[1].text}`,
              },
            },
          ],
        });
      } else {
        setAgreementContent({
          agreementsData: [
            {
              name: data[1].name,
              content: {
                __html: `${data[1].text}`,
              },
            },
            {
              name: data[0].name,
              content: {
                __html: `${data[0].text}`,
              },
            },
          ],
        });
      }
    } catch {
      setMessage(
        <MessageCallout
          type="error"
          title="Falha ao recuperar documentos"
          message="Não foi possível recuperar os Termos de Uso e Política de Privacidade. Por favor tente novamente mais tarde."
        />,
      );
      setTimeout(() => {
        setMessage(<></>);
      }, 5000);
    } finally {
      setIsLoadingContent(false);
    }
  };

  useEffect(() => {
    if (reset === RESET) document.title = 'Esqueci a senha - Redefinir';
    else document.title = 'Criar senha';
  }, []);

  useEffect(() => {
    setIsDirty(!formState.isValid);
  }, [formState]);

  useEffect(() => {
    getRetriveTermsOfUse();
  }, []);

  return (
    <PasswordFormContainer>
      {reset === RESET ? (
        <Presentation
          headline={
            <>
              Criar nova <b>senha</b>
            </>
          }
          subheading="Defina uma nova senha para sua conta"
        />
      ) : (
        <Presentation
          variant="primary-v2"
          headline={
            <>
              Crie uma <strong>senha de acesso</strong> para a sua conta NoVerde
            </>
          }
          subheading=""
        />
      )}

      <FormProvider {...methods}>
        <FormHolder>
          <form id="passwordForm" onSubmit={handleSubmit(onSubmit)}>
            <PasswordField
              name="password"
              label="Senha *"
              helperText="Mínimo 8 caracteres contendo letras e números."
              validation={createPassword}
              onInput={setConfirmPasswordError}
            />
            <PasswordField
              name="passwordConfirmation"
              label="Confirmar senha *"
              helperText="Preencha com a mesma senha de cima."
              validation={confirmPassword}
              onInput={setConfirmPasswordError}
            />
            {message}

            {reset !== RESET && (
              <TermsText>
                Ao clicar em Criar conta, você concorda com nossos{' '}
                <span onClick={() => handleModalTerms()}>Termos de uso</span> e{' '}
                <span onClick={() => handleModalTerms()}>
                  Políticas de privacidade
                </span>
                .
              </TermsText>
            )}
          </form>
        </FormHolder>
      </FormProvider>
    </PasswordFormContainer>
  );
}

PasswordForm.defaultProps = {
  reset: 'create-password',
};
