import { setState, StoreSlice } from 'zstore';

export interface IMessageBannerSlice {
  showBanner: boolean;
  setShowBanner: (value: boolean) => void;
}

export const messageBannerSlice: StoreSlice<IMessageBannerSlice> = () => ({
  showBanner: false,
  setShowBanner: (value) => {
    setState((state) => {
      state.showBanner = value;
    });
  },
});
