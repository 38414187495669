import { useQuery } from '@tanstack/react-query';
import { InsuranceDataTypes } from 'pages/IntroCreditLifeInsurance/types';
import InsuranceInfoService from 'services/InsuranceInfo/InsuranceInfoService';

export const useInsuranceInfo = () => {
  const query = useQuery({
    queryFn: getInsuranceInfo,
    queryKey: ['insuranceInfo'],
  });

  return {
    benefits: query.data?.benefits || [],
    unprotected: query.data?.unprotected || [],
    coverage: query.data?.coverage || [],
    ...query,
  };
};

export const getInsuranceInfo = async (): Promise<InsuranceDataTypes> => {
  const { data } = await InsuranceInfoService.getInsuranceInfo();

  return data;
};
