import styled, { css } from 'styled-components';
import { ColorsTypes, getThemeColors } from 'styles/helpers/getThemeColors';
import { isEmpty } from 'utils/helpers/is-empty';

import { BoxProps, FlexProps, SizeProps } from './types';

const colors = getThemeColors();

const renderPadding = (padding?: string) =>
  padding
    ? css`
        padding: ${padding};
      `
    : '';

const renderFlex = (flex?: Partial<FlexProps>) => {
  if (isEmpty(flex)) return '';

  return css`
    display: flex;
    ${flex?.flexDirection && `flex-direction: ${flex.flexDirection};`};
    ${flex?.flexWrap && `flex-wrap: ${flex.flexWrap};`};
    ${flex?.gap && `gap: ${flex.gap};`};
    ${flex?.alignItems && `align-items: ${flex.alignItems}`};
    ${flex?.justifyContent && `justify-content: ${flex.justifyContent}`};
  `;
};

const renderSize = (size?: Partial<SizeProps>) => {
  if (isEmpty(size)) return '';

  return css`
    ${size?.width && `width: ${size.width};`};
    ${size?.maxWidth && `max-width: ${size.maxWidth};`};
    ${size?.minWidth && `min-width: ${size.minWidth};`};

    ${size?.height && `height: ${size.height};`};
    ${size?.minHeight && `min-height: ${size.minHeight};`};
    ${size?.maxHeight && `max-height: ${size.maxHeight};`};
  `;
};

const renderMargin = (margin?: string) =>
  margin
    ? css`
        margin: ${margin};
      `
    : '';

const renderPosition = (position?: string) => {
  if (isEmpty(position)) return '';

  return css`
    position: ${position};
  `;
};

const renderBackGroundColor = (color?: keyof ColorsTypes) => {
  if (isEmpty(color)) return '';

  return css`
    background: ${color && colors[color]};
  `;
};

const renderBorderRadius = (borderRadius?: string) => {
  if (isEmpty(borderRadius)) return '';

  return css`
    border-radius: ${borderRadius};
  `;
};

const renderBorder = (border?: string) => {
  if (isEmpty(border)) return '';

  return css`
    border: ${border};
  `;
};

export const Box = styled.div<BoxProps>`
  ${({ _style }) => css`
    ${renderPadding(_style?.padding)}
    ${renderFlex(_style?.flex)}
    ${renderSize(_style?.size)}
    ${renderMargin(_style?.margin)}
    ${renderPosition(_style?.position)}
    ${renderBackGroundColor(_style?.backgroundColor)}
    ${renderBorderRadius(_style?.borderRadius)}
    ${renderBorder(_style?.border)}
  `}
`;
