import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';
import { dotzTheme } from 'styles/themes/dotz';

const colors = getThemeColors({
  dotz: {
    white: dotzTheme.colorNeutralDarkPure,
  },
});

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  margin-top: 20px;
  z-index: 0;
`;

export const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.md} {
    height: 50px;
  }
`;

export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  border-radius: 32px;
  border: 1px solid ${colors['action-contrast-disabled']};

  &:before {
    position: absolute;
    content: '';
    height: 30px;
    width: 45%;
    border-radius: 32px;
    left: 4px;
    bottom: 4px;
    background-color: ${colors['neutral-ultra-dark']};
    transition: 0.4s;

    @media ${device.md} {
      height: 40px;
    }
  }
`;

export const SwitchInputChecked = styled(SwitchInput)`
  &:checked + ${SwitchSlider}:before {
    transform: translateX(119%);
  }
`;
