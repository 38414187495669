import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { MdOutlineCheck } from 'react-icons/md';

import { Box } from 'components/Box';
import { Typography } from 'components/Typography';
import { getThemeColors } from 'styles/helpers/getThemeColors';

import { useInsuranceInfo } from '../hook/useInsuranceInfo';
import { Chip } from './Chip';
import { Loading } from './Loading';

import { ContainerIcon } from './styles';

export type LoanUnprotectedProps = {
  withCard?: boolean;
  chipValue?: string;
};

const colors = getThemeColors();

export const LoanProtected = ({
  withCard,
  chipValue,
}: LoanUnprotectedProps) => {
  const { benefits, isLoading } = useInsuranceInfo();

  return (
    <Box
      _style={
        withCard
          ? {
              flex: {
                flexDirection: 'column',
                gap: '0.5rem',
              },
              margin: '1rem 0',
            }
          : {
              flex: {
                flexDirection: 'column',
                gap: '1.5rem',
              },
              margin: '1rem 0 0 0',
            }
      }
    >
      {isLoading && <Loading />}

      {withCard && !isLoading && (
        <Box
          _style={{
            flex: {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box
            _style={{
              flex: {
                flexDirection: 'row',
                alignItems: 'center',
                gap: '1rem',
              },
              margin: '0 0 0.5rem',
            }}
          >
            <AiOutlineSafetyCertificate
              color={colors['action-navigation-static']}
              size={24}
            />

            <Typography
              _style={{
                default: 'body_b_mobile',
                xs: 'body_b_desktop',
                color: 'neutral-ultra-dark',
              }}
              as="p"
            >
              Empréstimo protegido
            </Typography>
          </Box>

          {chipValue && <Chip value={chipValue} />}
        </Box>
      )}

      {benefits.map((item) => (
        <Box
          key={String(item.description)}
          _style={{
            flex: {
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem',
            },
          }}
        >
          {!withCard ? (
            <ContainerIcon>
              <img src={item.logo_url} alt="troca e ganha" />
            </ContainerIcon>
          ) : (
            <MdOutlineCheck
              color={colors['action-navigation-static']}
              size={24}
            />
          )}

          <Typography
            _style={{
              default: 'body',
              xs: 'body-desktop',
              size: {
                width: '90%',
              },
              color: 'neutral-ultra-dark',
            }}
            as="p"
          >
            {item.description}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
