import produce from 'immer';
import { LoansSliceTypes } from 'types/Store/LoansSliceTypes';
import { storage } from 'utils/storage';
import create from 'zustand';
import { persist } from 'zustand/middleware';

import { ContractInfoSlice, IContractInfoSlice } from './ContractInfoSlice';
import { ILivenessSlice, livenessSlice } from './LivenessSlice';
import { loansSlice } from './LoansSlice';
import { IMessageBannerSlice, messageBannerSlice } from './MessageBannerSlice';
import { createPaymentsSlice, IPaymentsSlice } from './PaymentsSlice';
import { toastSlice } from './ToastSlice';
import { IToastSlice } from './ToastSlice';

export interface MessageToastProps {
  setCreditCardId: (data: any) => void;
}

export type StoreState = IPaymentsSlice &
  IToastSlice &
  LoansSliceTypes &
  IContractInfoSlice &
  ILivenessSlice &
  IMessageBannerSlice;

export type StoreSlice<T> = () => T;

export const useStore = create<StoreState>(
  persist(
    () => ({
      ...createPaymentsSlice(),
      ...toastSlice(),
      ...loansSlice(),
      ...ContractInfoSlice(),
      ...livenessSlice(),
      ...messageBannerSlice(),
    }),
    {
      name: 'main-storage',
      getStorage: () => storage,
      partialize: (state) => ({
        creditCardId: state.creditCardId,
        loanDetails: state.loanDetails,
      }),
    },
  ),
);

export const setState = (fn) => useStore.setState(produce(fn));
