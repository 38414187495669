import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from 'components/Box';
import { ListInsuranceInfo } from 'components/ListInsuranceInfo';
import { useRouter } from 'hooks/useRouter';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { Button } from 'components/Button';

import { Switch } from '../Switch';

import { ProposalProps } from '..';

const colors = getThemeColors();

type CreditLifeInsuranceCardProps = {
  toggleSwitch: () => void;
  switchValue?: boolean;
  chipValue?: string;
};

export const CreditLifeInsuranceCard = ({
  toggleSwitch,
  switchValue = true,
  chipValue,
}: CreditLifeInsuranceCardProps) => {
  const { history } = useRouter();
  const { state } = useLocation<ProposalProps>();

  return (
    <Box
      _style={{
        borderRadius: '4px',
        border: `1px solid ${colors['neutral-medium-2']}`,
        flex: {
          flexDirection: 'column',
          gap: '1rem',
        },
        padding: '1rem',
        margin: '1rem 0',
      }}
    >
      <ListInsuranceInfo
        kind={switchValue ? 'unprotected' : 'benefits'}
        chipValue={chipValue}
        withCard
      />

      <Switch toggleSwitch={toggleSwitch} switchValue={switchValue} />

      <Button
        className="secondary"
        onClick={() =>
          history.push('/know-more-credit-life-insurance', {
            proposal: state?.proposal,
          })
        }
      >
        Saiba mais
      </Button>
    </Box>
  );
};
