import ReactDOM from 'react-dom';
import { hotjar } from 'react-hotjar';
import { Router } from 'react-router';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'; // ou 'react-query' dependendo da versão
import GA4React from 'ga-4-react';
import { Store } from 'store';

import App from './App';
import history from './routes/history';

hotjar.initialize(
  process.env.REACT_APP_HOTJAR_ID,
  process.env.REACT_APP_HOTJAR_SV,
);

const MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID as string;
const ga4react = new GA4React(MEASUREMENT_ID);
const queryClient = new QueryClient();

(async () => {
  try {
    await ga4react.initialize();
  } catch {
    // eslint-disable-next-line no-console
    console.warn('Unable to initialize ga4react');
  }

  ReactDOM.render(
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <Store>
          <App />
        </Store>
      </QueryClientProvider>
    </Router>,
    document.getElementById('root'),
  );
})();
