import { Box } from 'components/Box';
import { ListInsuranceInfo } from 'components/ListInsuranceInfo';
import { Typography } from 'components/Typography';

export const CarouselCreditLifeInsurance = () => {
  return (
    <Box
      _style={{
        margin: '1rem 0 1rem',
        flex: {
          flexDirection: 'column',
          gap: '1rem',
        },
      }}
    >
      <Typography
        _style={{
          default: 'h3_b_desktop',
          xs: 'h3_b_desktop',
        }}
        as="p"
      >
        O que o seguro cobre{' '}
      </Typography>

      <ListInsuranceInfo kind="coverage" />
    </Box>
  );
};
