import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Button } from 'components/Button';
import { useRequirements } from 'contexts/RequirementsContext';
import firebase from 'firebase';
import { ProposalDetails } from 'pages/OfferApproved/ProposalDetails';
import PartnerOfferLoansRemoteService from 'services/Loans/Remote/V1/PartnerOfferLoans';
import {
  IOfferProps,
  IProposal,
  IProposalsProps,
  PartnerOffetLoansResponseData,
} from 'services/Loans/Remote/V1/PartnerOfferLoans/types';
import { storageKeys } from 'store/storage-keys';
import { isEmpty } from 'utils/helpers/is-empty';
import { toCurrencyBrl } from 'utils/masks';
import { storage } from 'utils/storage';

// eslint-disable-next-line import-helpers/order-imports
import { useModal } from './useModal';
// eslint-disable-next-line import/order
import { ModalContent } from 'pages/OfferApproved/style';

export type MinMaxSliderProps = {
  step: number;
  value: number;
};

export interface SelectedOfferRangeProps extends IOfferProps {
  label: string;
  value: string;
}

export const useLoadPartnerOffer = (
  authToken: string,
  setIsLoadingNavigation: Dispatch<SetStateAction<boolean>>,
) => {
  const { creditAnalysisRequirements } = useRequirements();

  const [partnerOffetLoansData, setPartnerOffetLoansData] =
    useState<PartnerOffetLoansResponseData>();
  const [proposals, setProposals] = useState<IProposalsProps>();
  const [proposal, setProposal] = useState<IProposal>();
  const [offerGroupKeys, setOfferGroupKeys] = useState<number[]>([]);
  const [minMaxSlider, setMinMaxSlider] = useState<MinMaxSliderProps[]>([
    { step: 1, value: 0 },
    { step: 2, value: 0 },
  ]);
  const [stepSlider, setStepSlider] = useState<number>(0);
  const [selectedOffer, setSelectedOffer] = useState<IOfferProps>(
    {} as IOfferProps,
  );
  const [startIndexSlider, setStartIndexSlider] = useState<number>(0);
  const [selectedOfferRange, setSelectedOfferRange] = useState<
    SelectedOfferRangeProps[]
  >([]);

  const [insuranceEnabled, setInsuranceEnabled] = useState(false);
  const [isAlternativeScenario, setIsAlternativeScenario] = useState<
    boolean | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const { openModal, closeModal } = useModal();

  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(true);

  const [updateInstallments, setUpdateInstallments] = useState<
    undefined | boolean
  >();

  const checkActiveInsurance = () => {
    if (insuranceEnabled) {
      if (isAlternativeScenario) {
        return isChecked;
      }

      if (updateInstallments === undefined) {
        return true;
      }

      return !switchValue;
    }

    return false;
  };

  const addInsuranceValue = (offer) => {
    if (checkActiveInsurance()) {
      return (offer.installment_value + offer.insurance_monthly_fee).toFixed(2);
    }

    return offer.installment_value.toFixed(2);
  };

  const closestValue = (onlyPeriods: number[], findPeriod: number) =>
    onlyPeriods.reduce((accumulator, _current) => {
      return Math.abs(_current - findPeriod) <
        Math.abs(accumulator - findPeriod)
        ? _current
        : accumulator;
    });

  const getInstallments = (data: IOfferProps[]) =>
    data.map((offer, index) => ({
      ...offer,
      value: index.toString(),
      label: `${offer.period}x ${toCurrencyBrl(addInsuranceValue(offer))}`,
    }));

  const setOrder = (first, second) =>
    first.period < second.period ? -1 : first.period > second.period ? 1 : 0;

  const handleSlider = (value: number) => {
    const currentKey = offerGroupKeys[value];

    const currentSelectedOffer = proposals?.offers?.[currentKey];

    const newOfferSelected = currentSelectedOffer?.sort(setOrder);

    const newOfferRangeSelected = getInstallments(
      newOfferSelected as IOfferProps[],
    );

    if (newOfferSelected && currentSelectedOffer) {
      const periods = currentSelectedOffer.map(
        (currentOffer) => currentOffer.period,
      );

      const newPeriod = closestValue(periods, selectedOffer.period);

      const currentOffer: IOfferProps =
        newOfferRangeSelected.find((_offer) => _offer.period === newPeriod) ||
        ({} as IOfferProps);

      setSelectedOffer(currentOffer);
    }

    if (newOfferRangeSelected) {
      setSelectedOfferRange(newOfferRangeSelected);
    }

    // setUpdateInstallments((prev) => !prev);
  };

  const loadOffer = async () => {
    setIsLoadingNavigation(true);
    try {
      const { data: proposalResponse } =
        await PartnerOfferLoansRemoteService.requestPartnerOfferLoans(
          authToken,
        );

      if (!isEmpty(proposalResponse)) {
        setPartnerOffetLoansData(proposalResponse);
        setProposals(proposalResponse.data.proposals);
        setProposal(proposalResponse.data.proposal);

        setInsuranceEnabled(!!proposalResponse?.data?.insurance_enabled);

        if (proposalResponse?.data?.insurance_enabled) {
          setUpdateInstallments((prev) => !prev);
        }
      }
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoadingNavigation(false);
    }
  };

  const requestRemoteConfig = async () => {
    try {
      setLoading(true);

      const createRemoteConfig = firebase.remoteConfig();
      createRemoteConfig.settings.minimumFetchIntervalMillis = 3600000;

      await createRemoteConfig.fetchAndActivate();

      const insuranceAbTest = createRemoteConfig
        .getValue('insurance_ab_test')
        .asBoolean();

      setIsAlternativeScenario(insuranceAbTest);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = () => {
    if (isAlternativeScenario) {
      setIsChecked((prev) => !prev);
    } else {
      setSwitchValue((prevState) => !prevState);
    }

    setUpdateInstallments((prev) => !prev);
  };

  const updateOfferRange = () => {
    const newOffers = getInstallments(selectedOfferRange);

    setSelectedOfferRange(newOffers);

    const currentSelectedOffer: IOfferProps =
      newOffers.find((offer) => offer.uuid === selectedOffer.uuid) ||
      ({} as IOfferProps);

    setSelectedOffer(currentSelectedOffer);
  };

  const handleSelect = (value) => {
    const index = Number(value.value);

    setSelectedOffer(selectedOfferRange[index]);
  };

  const openDetailModal = () => {
    openModal({
      title: 'Detalhes do empréstimo',
      size: 500,
      alignSelf: 'flex-end',
      closeButtonPosition: 'right',
      content: (
        <ModalContent>
          <ProposalDetails
            proposal={selectedOffer}
            hasActiveInsurance={checkActiveInsurance()}
          />
        </ModalContent>
      ),
      fixedContent: <Button onClick={closeModal}>Voltar</Button>,
    });
  };

  useEffect(() => {
    if (partnerOffetLoansData && proposals && proposal) {
      const allOfferKeys = Object.keys(proposals.offers).map((keyOffer) =>
        Number(keyOffer),
      );

      setOfferGroupKeys(allOfferKeys);

      if (allOfferKeys.length > 1) {
        setMinMaxSlider([
          {
            step: 0,
            value: allOfferKeys[0] as number,
          },
          {
            step: allOfferKeys.length - 1,
            value: allOfferKeys[allOfferKeys.length - 1] as number,
          },
        ]);

        setStepSlider(1);
      }

      const startOfferKey = closestValue(
        allOfferKeys,
        proposal?.granted_amount as number,
      );

      setStartIndexSlider(
        allOfferKeys.findIndex((element) => element === startOfferKey),
      );

      const startRangeOffers = getInstallments(
        proposals?.offers[startOfferKey],
      );

      setSelectedOfferRange(startRangeOffers);

      if (creditAnalysisRequirements.includes('requested_period')) {
        const currentSelectedPeriodOffers: number[] = startRangeOffers.map(
          (item: { period: number }) => item.period,
        );

        const currentPeriodOffer = closestValue(
          currentSelectedPeriodOffers,
          partnerOffetLoansData?.data.requested_period as number,
        );

        const startSelectedOffer: IOfferProps =
          startRangeOffers.find(
            (_offer) => _offer.period === currentPeriodOffer,
          ) || ({} as IOfferProps);

        setSelectedOffer(startSelectedOffer);
      } else {
        const startStandardOffer =
          startRangeOffers[startRangeOffers.length - 1];

        setSelectedOffer(startStandardOffer);
      }
    }
  }, [partnerOffetLoansData, proposals, proposal]);

  useEffect(() => {
    if (updateInstallments !== undefined) {
      updateOfferRange();
    }
  }, [updateInstallments]);

  useEffect(() => {
    loadOffer();
  }, [authToken, setIsLoadingNavigation]);

  useEffect(() => {
    if (authToken) {
      storage.setItem(storageKeys.accessToken, authToken);
    }
  }, [authToken]);

  useEffect(() => {
    requestRemoteConfig();
  }, []);

  return {
    partnerOffetLoansData,
    handleSlider,
    minMaxSlider,
    stepSlider,
    selectedOffer,
    selectedOfferRange,
    startIndexSlider,
    insuranceEnabled,
    loading,
    isAlternativeScenario,
    switchValue,
    isChecked,
    handleOnChange,
    updateInstallments,
    openDetailModal,
    handleSelect,
  };
};
