import { Box } from 'components/Box';
import { Typography } from 'components/Typography';

import {
  SwitchContainer,
  SwitchInputChecked,
  SwitchLabel,
  SwitchSlider,
} from './styles';

type SwitchProps = {
  toggleSwitch: () => void;
  switchValue: boolean;
};

export const Switch = ({ switchValue, toggleSwitch }: SwitchProps) => {
  return (
    <SwitchContainer>
      <SwitchLabel>
        <Box
          _style={{
            flex: {
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem',
              justifyContent: 'space-around',
            },
            size: {
              width: '100%',
            },
          }}
        >
          <Typography
            _style={{
              default: !switchValue ? 'body_b_mobile' : 'body',
              xs: !switchValue ? 'body_b_desktop' : 'body-desktop',
              color: !switchValue
                ? 'neutral-ultra-light'
                : 'neutral-ultra-dark',
            }}
            as="p"
            style={{
              zIndex: 5,
              cursor: 'pointer',
            }}
          >
            Com seguro
          </Typography>
          <Typography
            _style={{
              default: switchValue ? 'body_b_mobile' : 'body',
              xs: switchValue ? 'body_b_desktop' : 'body-desktop',
              color: switchValue ? 'neutral-ultra-light' : 'neutral-ultra-dark',
            }}
            style={{
              zIndex: 5,
              cursor: 'pointer',
            }}
            as="p"
          >
            Sem seguro
          </Typography>
        </Box>
        <SwitchInputChecked
          type="checkbox"
          checked={switchValue}
          onChange={toggleSwitch}
          value="test"
        />
        <SwitchSlider />
      </SwitchLabel>
    </SwitchContainer>
  );
};
