import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { RiCloseLine } from 'react-icons/ri';

import { Box } from 'components/Box';
import { Typography } from 'components/Typography';
import { getThemeColors } from 'styles/helpers/getThemeColors';

import { useInsuranceInfo } from '../hook/useInsuranceInfo';
import { Chip } from './Chip';
import { Loading } from './Loading';
import { LoanUnprotectedProps } from './LoanProtected';

const colors = getThemeColors();

export const LoanUnprotected = ({
  chipValue,
}: Pick<LoanUnprotectedProps, 'chipValue'>) => {
  const { unprotected, isLoading } = useInsuranceInfo();

  return (
    <Box
      _style={{
        flex: {
          flexDirection: 'column',
          gap: '0.5rem',
        },
        margin: '1rem 0',
      }}
    >
      {isLoading && <Loading />}

      {!isLoading && (
        <Box
          _style={{
            flex: {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          }}
        >
          <Box
            _style={{
              flex: {
                flexDirection: 'row',
                alignItems: 'center',
                gap: '1rem',
              },
              margin: '0 0 0.5rem',
            }}
          >
            <AiOutlineSafetyCertificate
              color={colors['support-red']}
              size={24}
            />

            <Typography
              _style={{
                default: 'body_b_mobile',
                xs: 'body_b_desktop',
                color: 'neutral-ultra-dark',
              }}
              as="p"
            >
              Empréstimo desprotegido
            </Typography>
          </Box>

          {chipValue && <Chip value={chipValue} />}
        </Box>
      )}

      {unprotected.map((item) => (
        <Box
          key={String(item.description)}
          _style={{
            flex: {
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1rem',
            },
          }}
        >
          <RiCloseLine color={colors['support-red']} size={24} />

          <Typography
            _style={{
              default: 'body',
              xs: 'body-desktop',
              size: {
                width: '90%',
              },
              color: 'neutral-ultra-dark',
            }}
            as="p"
          >
            {item.description}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
