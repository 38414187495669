/* eslint-disable import/order */
import BannerWomanSimulation from 'assets/img/SideBanner/dotz/woman-simulation-dotz.jpg';
import Banner from 'assets/img/SideBanner/woman-simulation.jpg';
import { Box } from 'components/Box';
import { ButtonLoader } from 'components/ButtonLoader';
import { ListInsuranceInfo } from 'components/ListInsuranceInfo';
import { Navigation } from 'components/Navigation';
import { Presentation } from 'components/Presentation';
import { SideBanner } from 'components/SideBanner';
import { Typography } from 'components/Typography';
import { WithFixedContent } from 'components/WithFixedContent';
import { useRouter } from 'hooks/useRouter';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { SideBySideLayout } from 'styles/grids';
import { getThemeAssets } from 'styles/helpers/getThemeAssets';

import { CarouselCreditLifeInsurance } from './components/CarouselCreditLifeInsurance';

const assets = getThemeAssets({
  default: {
    banner: Banner,
  },
  dotz: {
    banner: BannerWomanSimulation,
  },
});

export default function KnowMoreCreditLifeInsurance() {
  const { hidelg } = useWindowDimensions();
  const { history } = useRouter();

  return (
    <SideBySideLayout>
      {hidelg && (
        <SideBanner
          title={
            <>
              Faça sua
              <br /> <b>simulação</b>
            </>
          }
          banner={String(assets?.banner)}
        />
      )}

      <WithFixedContent
        fixedContentTop={<Navigation />}
        fixedContentBottom={
          <ButtonLoader
            isLoading={false}
            form="incomeForm"
            onClick={() => history.goBack()}
          >
            Ok, entendi
          </ButtonLoader>
        }
      >
        <Box
          _style={{
            flex: {
              flexDirection: 'column',
              gap: '1rem',
            },
            margin: '1rem 0',
          }}
        >
          <Presentation
            headline="Segurança financeira para empréstimo pessoal"
            subheading="O seguro é uma forma de você se proteger de imprevistos e conta com o pagamento total ou parcial do seu empréstimo de acordo com a cobertura."
            variant="primary-v2"
          />

          <Typography
            _style={{
              default: 'h3_b_desktop',
              xs: 'h3_b_desktop',
            }}
            as="p"
            style={{ marginTop: '1rem' }}
          >
            Vantagens ao contratar o seguro para o seu empréstimo!{' '}
          </Typography>

          <ListInsuranceInfo kind="benefits" />

          <CarouselCreditLifeInsurance />

          <Typography
            _style={{
              default: 'h3_b_desktop',
              xs: 'h3_b_desktop',
            }}
            as="p"
          >
            Como funciona{' '}
          </Typography>

          <Typography
            _style={{
              default: 'body',
              xs: 'body-desktop',
            }}
            as="p"
          >
            O seguro prestamista pode ser contratado individualmente para cada
            empréstimo e é pago junto das parcelas do mesmo.{' '}
          </Typography>

          <Typography
            _style={{
              default: 'body',
              xs: 'body-desktop',
            }}
            as="p"
          >
            Após o tempo de carência, você pode acionar o seguro a qualquer
            momento na ocorrência de um sinistro para cobrir total ou
            parcialmente o seu empréstimo de acordo com a cobertura prevista no
            contrato.
          </Typography>

          <Typography
            _style={{
              default: 'body',
              xs: 'body-desktop',
            }}
            as="p"
          >
            Em caso de cancelamento, fique tranquilo! Não cobramos nenhuma multa
            para cancelar o seu seguro prestamista.
          </Typography>
        </Box>
      </WithFixedContent>
    </SideBySideLayout>
  );
}
