import { InsuranceCoverage } from './components/InsuranceCoverage';
import { LoanProtected } from './components/LoanProtected';
import { LoanUnprotected } from './components/LoanUnprotected';

type LoanProtectedInfoProps = {
  kind: 'benefits' | 'unprotected' | 'coverage';
  withCard?: boolean;
  chipValue?: string;
  showRequirements?: boolean;
};
export const ListInsuranceInfo = ({
  kind,
  withCard = false,
  chipValue,
  showRequirements,
}: LoanProtectedInfoProps) => {
  const components = {
    benefits: <LoanProtected withCard={withCard} chipValue={chipValue} />,
    unprotected: <LoanUnprotected chipValue={chipValue} />,
    coverage: <InsuranceCoverage showRequirements={showRequirements} />,
  };

  return components[kind];
};
