import { useLocation } from 'react-router-dom';

import { Box } from 'components/Box';
import { Typography } from 'components/Typography';
import { useRouter } from 'hooks/useRouter';
import { getThemeColors } from 'styles/helpers/getThemeColors';

import { CustomCheckbox } from './style';

import { ProposalProps } from '..';

const colors = getThemeColors();

type InsuranceCheckboxProps = {
  handleOnChange: () => void;
  isChecked: boolean;
};

export const InsuranceCheckbox = ({
  isChecked,
  handleOnChange,
}: InsuranceCheckboxProps) => {
  const { history } = useRouter();
  const { state } = useLocation<ProposalProps>();

  return (
    <Box
      _style={{
        flex: {
          flexDirection: 'row',
          alignItems: 'center',
          gap: '1rem',
          justifyContent: 'space-around',
        },
        size: {
          width: '100%',
        },
        padding: '16px 8px',
      }}
    >
      <div>
        <CustomCheckbox
          color={colors.galaxy}
          onChange={handleOnChange}
          checked={isChecked}
        />
      </div>

      <Typography
        _style={{
          default: 'small',
          xs: 'small',
        }}
        as="p"
      >
        Declaro que concordo com a contratação do seguro prestamista, que
        garantirá a cobertura de até 3 parcelas do meu empréstimo em caso de
        sinistro.
        <Typography
          _style={{
            default: 'body_b_mobile',
            xs: 'body_b_mobile',
            color: 'brand-primary',
            cursor: 'pointer',
          }}
          onClick={() =>
            history.push('/know-more-credit-life-insurance', {
              proposal: state?.proposal,
            })
          }
          as="p"
        >
          Ver detalhes do seguro.
        </Typography>
      </Typography>
    </Box>
  );
};
