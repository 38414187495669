/* eslint-disable array-callback-return */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
import { useState, ChangeEvent, useEffect } from 'react';

import { ButtonLoader } from 'components/ButtonLoader';
import { useDBTracking } from 'contexts/DBTrackingContext';
import { addDays, eachDayOfInterval } from 'date-fns';
import { useRouter } from 'hooks/useRouter';
import { ContentLoading } from 'pages/Dashboard/components/ContentLoading';
import { InstallmentDashboard } from 'pages/Dashboard/components/InstallmentDashboard';
import { InstallmentDateSelectorDashboard } from 'pages/Dashboard/components/InstallmentDateSelectorDashboard';
import InfoContractService from 'services/InfoContract/InfoContractService';
import PaymtService from 'services/Paymt/PaymtService';
import { storageKeys } from 'store/storage-keys';
import { CheckoutTypes } from 'types/Checkout/CheckoutTypes';
import { InstallmentsTypes } from 'types/InfoContract/InfoContractTypes';
import { formatDate } from 'utils/formatters/format-date';
import { formatInCamelCase } from 'utils/formatters/to-formated-camalCase';
import { isEmpty } from 'utils/helpers/is-empty';
import { storage } from 'utils/storage';
import { useStore } from 'zstore';

import { ModalInstallmentDateSelectorDashboard } from '../ModalInstallmentDateSelectorDashboard';

import { BoxShadow, Title, ScrollList } from './style';

const DAY = 1;

export type AllInstallmentsTypes = {
  presentValue: number;
  uuid: string;
  disabled: boolean;
  checked: boolean;
} & InstallmentsTypes;

export const InstallmentsListDashboard = () => {
  const {
    availableForPayment,
    allInstallments,
    setContractInfo,
    financialFlows,
  } = useStore();
  const { history } = useRouter();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [openModalDates, setOpenModalDates] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [dueDates, setDueDates] = useState<string[]>([]);
  const [listInstallments, setListInstallments] = useState<
    AllInstallmentsTypes[]
  >([]);

  const { setTrackingEvent } = useDBTracking();
  const [totalInstallments, setTotalInstallments] = useState<number>(0);

  const [isLoading, setIsLoading] = useState(false);

  const pendingInstallments = listInstallments.filter(
    (_installment) => _installment.status === 'late',
  );

  const currentInstallments = listInstallments.filter(
    (_installment) => _installment.status === 'current',
  );

  function toggleModal() {
    setOpenModalDates(!openModalDates);
  }

  function handleSelectedDate(date: string) {
    setSelectedDate(date);
  }

  function toggleCheckbox(
    e: ChangeEvent<HTMLInputElement>,
    item: AllInstallmentsTypes,
  ) {
    const installmentsGroup = [...listInstallments];

    installmentsGroup.forEach((_installment) => {
      if (_installment.uuid === item.uuid) {
        _installment.checked = !_installment.checked;
        return _installment;
      }
      return _installment;
    });

    const indexSelectedInstallment = installmentsGroup.indexOf(item);

    if (e.target.checked) {
      if (indexSelectedInstallment + 1 === installmentsGroup.length) {
        setListInstallments(installmentsGroup);

        return;
      }

      installmentsGroup[indexSelectedInstallment + 1].disabled = false;
      setListInstallments(installmentsGroup);
    } else {
      for (
        let installmentIndex = indexSelectedInstallment;
        installmentIndex < installmentsGroup.length;
        installmentIndex++
      ) {
        installmentsGroup[installmentIndex].checked = false;
        installmentsGroup[installmentIndex].disabled =
          installmentIndex === 0
            ? false
            : installmentIndex !== indexSelectedInstallment;
      }
      setListInstallments(installmentsGroup);
    }
  }

  function resetSelectedInstallments() {
    setListInstallments([] as AllInstallmentsTypes[]);
  }

  async function createCheckout() {
    setButtonLoading(true);

    try {
      const selectedInstallments = listInstallments.filter(
        (_installment) => _installment.checked,
      );

      const { data } = await PaymtService.createCheckout({
        payment_date: selectedDate,
        installment_uuids: selectedInstallments.map(
          (_installment) => _installment.uuid,
        ),
      });

      const checkout: CheckoutTypes = {
        ...formatInCamelCase(data),
        installments: selectedInstallments,
      };

      history.push({
        pathname: '/dashboard/payment-methods',
        state: {
          ...checkout,
          expensesMonthlyValue: financialFlows?.expensesMonthlyValue,
        },
      });
    } finally {
      setButtonLoading(false);
    }
  }

  const calculateTotalAmountInstallments = () => {
    const calculatedValue = listInstallments
      .filter((_installment) => _installment.checked)
      .reduce((total, installment) => {
        const value = installment.presentValue;
        const sum = total + value;
        return sum;
      }, 0);

    setTotalInstallments(calculatedValue);
  };

  function sendTrackingEventInstallments() {
    setTrackingEvent({
      category: 'dashboard-installments',
      action: 'continue_button_click',
      loanUuid: true,
      deviceId: true,
    });
  }

  const setDates = () => {
    if (
      availableForPayment?.paymentDate !== availableForPayment.maxPaymentDate
    ) {
      const startDate = addDays(new Date(availableForPayment.paymentDate), DAY);

      const finishDate = addDays(
        new Date(availableForPayment.maxPaymentDate),
        DAY,
      );

      const dates = eachDayOfInterval({
        start: startDate,
        end: finishDate,
      }).map((_date) =>
        formatDate(_date.toLocaleDateString(), {
          format: 'YYYY-MM-DD',
        }),
      );

      setDueDates(dates);
    }
  };

  const getInstallments = () => {
    setIsLoading(true);

    const filterInstallmentByType = (installmentType) =>
      allInstallments
        .filter((_installment) => _installment.status === installmentType)
        .map((_installment) => {
          const currentInstallment = availableForPayment.installments.find(
            (_valueInstallment) => _valueInstallment.uuid === _installment.uuid,
          );

          return currentInstallment
            ? { ...currentInstallment, ..._installment }
            : null;
        });

    const newLateInstallments = filterInstallmentByType('late');

    const newCurrentInstallments = filterInstallmentByType('current');

    const installments = [
      ...newLateInstallments,
      ...newCurrentInstallments,
    ].map((_installment, index) => ({
      ..._installment,
      disabled: index !== 0,
      checked: false,
    })) as AllInstallmentsTypes[];

    setListInstallments(installments);
    setIsLoading(false);
  };

  const fetchInstallments = async () => {
    try {
      setIsLoading(true);
      const loanUid = storage.getItem(storageKeys.loanUid);

      const { data } = await InfoContractService.getInfoContract({
        uid: loanUid,
      });

      setContractInfo(formatInCamelCase(data));
    } catch (err) {
      console.warn({
        title: 'Erro ao carregar installments',
        err,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEmpty(dueDates) && !isEmpty(availableForPayment)) {
      setDates();

      setSelectedDate(String(availableForPayment.paymentDate));
    }
  }, [availableForPayment]);

  useEffect(() => {
    calculateTotalAmountInstallments();
  }, [listInstallments]);

  useEffect(() => {
    if (!isEmpty(availableForPayment)) {
      getInstallments();
    } else {
      fetchInstallments();
    }
  }, [availableForPayment]);

  useEffect(() => {
    return () => {
      fetchInstallments();
    };
  }, []);

  return (
    <BoxShadow>
      {isLoading || isEmpty(listInstallments) ? (
        <ContentLoading />
      ) : (
        <>
          {!isEmpty(dueDates) && (
            <>
              <Title>Selecione a data de pagamento</Title>

              <InstallmentDateSelectorDashboard onClick={toggleModal}>
                {formatDate(selectedDate, {
                  format: 'DD MMM YYYY - EEEE',
                })}
              </InstallmentDateSelectorDashboard>
            </>
          )}

          <ScrollList>
            <InstallmentDashboard
              currentInstallments={currentInstallments}
              pendingInstallments={pendingInstallments}
              toggleCheckbox={toggleCheckbox}
            />
          </ScrollList>
        </>
      )}

      <ButtonLoader
        isLoading={buttonLoading}
        disabled={totalInstallments === 0}
        onClick={() => {
          sendTrackingEventInstallments();
          createCheckout();
        }}
      >
        ir para resumo
      </ButtonLoader>

      {!isEmpty(dueDates) && (
        <ModalInstallmentDateSelectorDashboard
          open={openModalDates}
          toggleModal={toggleModal}
          handleSelectedDate={handleSelectedDate}
          resetSelectedInstallments={resetSelectedInstallments}
          setIsLoading={setIsLoading}
          dueDates={dueDates}
        />
      )}
    </BoxShadow>
  );
};
