import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { device } from 'styles/medias';

export const Stack = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

interface TextProps {
  color?: string;
  fontSize?: string | number;
  fontWeight?: string | number;
  isTitle?: boolean;
}

export const Text = styled.p<TextProps>`
  ${({ fontSize, color, fontWeight, isTitle }) => css`
    color: ${color ? colors[color] : colors.text};
    font-size: ${fontSize && isTitle ? '16px' : '14px'};
    font-weight: ${fontWeight || 400};

    @media ${device.sm} {
      font-size: ${fontSize || '20px'};
    }
  `}
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background: ${colors.greyborders};
`;
