/* eslint-disable no-shadow */
import Skeleton from 'react-loading-skeleton';
import Select, { components } from 'react-select';

import { RadioItem } from '../RadioItem';

import { LabelStyled, SelectContainer } from './style';

interface optionProp {
  value: string;
  label: string;
}

interface SelectFieldProps {
  label?: string;
  placeholder?: string;
  menuPlacement?: string;
  options: optionProp[];

  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  value: {
    value: string;
    label: string;
  };
  onChange: (value) => void;
}

const CustomOption = (props) => {
  const { children, isSelected } = props;

  return (
    <components.Option {...props} style={{ marginBottom: 20 }}>
      <RadioItem content={children} selected={isSelected} />
    </components.Option>
  );
};

export function CustomSelect(props: SelectFieldProps) {
  const {
    label,
    placeholder,
    menuPlacement,
    options,
    isSearchable,
    isDisabled,
    isLoading = false,
    value,
    onChange,
  } = props;

  if (isLoading) return <Skeleton height={56} />;

  return (
    <SelectContainer>
      {label && <LabelStyled>{label}</LabelStyled>}

      <Select
        options={options}
        components={{ Option: CustomOption }}
        classNamePrefix="installments"
        placeholder={placeholder}
        menuPlacement={menuPlacement}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        value={value}
        onChange={onChange}
      />
    </SelectContainer>
  );
}

CustomSelect.defaultProps = {
  label: null,
  placeholder: 'selecionar',
  menuPlacement: 'auto',
  isSearchable: false,
  isDisabled: false,
};
