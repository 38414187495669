import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { storageKeys } from 'store/storage-keys';
import { OfferSelectedType } from 'types/Loans/OfferType';
import { storage } from 'utils/storage';

class LoansService extends HttpClient {
  public constructor() {
    super(Services.PlatformLoans);
  }

  public getStatusLoan = () => {
    return this.instance.get(
      `${storage.getItem(storageKeys.loanUid)}?offers=proposals`,
    );
  };

  public getStatusLoanByUid = (uid: string) => {
    return this.instance.get(`${uid}?offers=proposals`);
  };

  public getLoanByUid = (loanUid: string) => {
    return this.instance.get(loanUid);
  };

  public getLeaveOfferReasons = () => {
    return this.instance.get('/feedbacks');
  };

  public setOfferSelected = (body: OfferSelectedType) => {
    return this.instance.put(
      `${storage.getItem(storageKeys.loanUid)}/offers`,
      body,
    );
  };

  public getAgreementsByLoan = (filters?: string) => {
    const baseUrl = `/${storage.getItem(storageKeys.loanUid)}/agreements`;
    const withFilter = `${baseUrl}?filters=${filters}`;

    return this.instance.get(filters ? withFilter : baseUrl);
  };

  public cancelInsurance = () => {
    return this.instance.post(
      `${storage.getItem(storageKeys.loanUid)}/insurances/cancel`,
      {
        cancel_reason: 'user_requested',
      },
    );
  };
}

export default new LoansService();
