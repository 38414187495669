import { Box } from 'components/Box';
import { Typography } from 'components/Typography';

type ChipProps = {
  value: string;
};

export const Chip = ({ value }: ChipProps) => {
  return (
    <Box
      _style={{
        backgroundColor: 'neutral-ultra-dark',
        size: {
          minHeight: '15px',
        },
        borderRadius: '24px',
        flex: {
          alignItems: 'center',
        },
        padding: '0.5rem',
      }}
    >
      <Typography
        _style={{
          default: 'small_b',
          xs: 'small_b',
          textAlign: 'center',
          color: 'neutral-ultra-light',
        }}
      >
        {value} / mês
      </Typography>
    </Box>
  );
};
