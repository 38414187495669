/* eslint-disable import/no-duplicates */

import { Box } from 'components/Box';
import { Typography } from 'components/Typography';
import { OffersProps } from 'pages/OfferApproved';
import { IOfferProps } from 'services/Loans/Remote/V1/PartnerOfferLoans/types';
import { toCurrency, toCurrencyBrl } from 'utils/masks';

import { Divider } from './style';

type ProposalProps = {
  proposal: OffersProps | IOfferProps;
  hasActiveInsurance?: boolean;
};

export function ProposalDetails({
  proposal,
  hasActiveInsurance,
}: ProposalProps) {
  const getDetails = () => {
    const details = [
      {
        title: 'Taxa de juros',
        value: `${toCurrency(proposal.interest_rate?.toFixed(2))}% ao mês`,
      },
      {
        title: 'Tarifa de Cadastro',
        value: toCurrencyBrl(proposal.partner_fee?.toFixed(2)),
        description:
          'É o valor cobrado pelas Instituições Financeiras para cobrir os custos do processamento da abertura da operação de crédito.',
      },
      {
        title: 'IOF',
        value: toCurrencyBrl(proposal.iof_fee?.toFixed(2)),
        description:
          'Essa é a sigla de Imposto sobre Operações Financeiras de crédito, câmbio e seguros que deve ser cobrada em todo empréstimo oferecido no Brasil.',
      },
      {
        title: 'Custo efetivo total',
        value: `${toCurrency(
          proposal.annual_percentage_rate?.toFixed(2),
        )}% ao ano`,
        description: '',
      },
      {
        title: 'Valor financiado',
        value: toCurrencyBrl(proposal?.contract_value?.toFixed(2)),
        description:
          'Soma do valor que você pegou emprestado com os custos a cima (Não incluso valor do seguro).',
      },
    ];

    if (hasActiveInsurance) {
      details.splice(details.length - 1, 0, {
        title: 'Seguro Prestamista',
        value: `${toCurrencyBrl(
          proposal?.insurance_monthly_fee?.toFixed(2),
        )} / mês`,
      });
    }

    return details;
  };

  return (
    <Box
      _style={{
        flex: {
          flexDirection: 'column',
        },
      }}
    >
      {getDetails().map((detail) => (
        <Box
          _style={{
            flex: {
              flexDirection: 'column',
              gap: '8px',
            },
          }}
        >
          <Typography
            _style={{
              default: 'body',
              xs: 'body-desktop',
            }}
            as="p"
          >
            {detail.title}
          </Typography>

          <Typography
            _style={{
              default: 'h3_b_mobile',
              xs: 'h3_b_desktop',
            }}
            as="p"
          >
            {detail.value}
          </Typography>

          {detail.description && (
            <Typography
              _style={{
                default: 'body',
                xs: 'body-desktop',
              }}
              as="p"
            >
              {detail.description}
            </Typography>
          )}

          <Divider />
        </Box>
      ))}
    </Box>
  );
}
